.member {
	// height: 595px;
	margin: 0 30px;

	&__image {
		width: 377px;
		height: 595px;
		border-radius: 39px;
	}

	&__info {
		position: relative;
		bottom: 340px;
		height: 0;

		display: flex;
		flex-direction: column;
		margin-left: 39px;

		&__name {
			margin: 24px 0px;

			font-weight: 900;
			font-size: 35px;
			line-height: 104%;

			background: -webkit-linear-gradient(180deg, #ac1ec3 0%, #7a17a8 100%);
			-webkit-background-clip: text;
			-webkit-text-stroke: 0.76px transparent;
			text-shadow: 0px 2.6px 1.3px rgba(38, 22, 133, 0.22),
				0px 1.04px 0px #7a17a8;
		}

		&__position {
			
			width: fit-content;
			padding: 5.1px 15.6px;

			font-family: 'Nunito', sans;
			font-weight: 800;
			font-size: 19.5px;
			line-height: 107%;
			margin-bottom: 22.8px;

			border-radius: 32.5px;
			background: linear-gradient(
				180deg,
				#ffbe46 0%,
				#ffb53d 6.25%,
				#ef7c31 52.08%,
				#ee6e2f 81.77%,
				#ed632d 100%
			);
			box-shadow: 0px -2.6px 9.1px rgba(255, 212, 100, 0.25),
				inset 0px 1.3px 1.3px rgba(255, 168, 37, 0.65),
				inset 0px 1.3px 6.5px rgba(255, 236, 139, 0.5);
		}
		&__description{
			font-family: 'Nunito', sans;
			font-weight: 400;
			font-style: normal; 
			font-size: 18px;
			color: #F3F1F3;
			opacity: 0.9;
			line-height: 30px;
			letter-spacing: -0.05px;
			width: 306px;
		}
	}
}

@media (max-width: 1279px) {
	.member {
		margin: 0 0 29px 0;
		border-radius: 39px;

		&__image {
			width: 314px;
			object-fit: cover;
			// height: 600px;
		}

		&__info {
			bottom: 370px;
			margin-left: 30px;

			&__name {
				margin: 20px 0px;
				font-size: 27px;
			}

			&__position {
				margin-right: 15px;
				padding: 4px 13px;
				line-height: 21px;
				font-size: 16.5px;
				margin-bottom: 30px;
			}
			&__description{
				width: 254px;
				// font-size: 14px;
				// line-height: 167%
			}
		}
	}
}
