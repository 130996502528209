.gameplay {
	width: 1280px;
	margin-bottom: 233px;

	display: flex;
	flex-direction: column;
	align-items: center;

	&__title {
		width: 920px;
		margin: 0 0 90px;

		font-weight: 900;
		font-size: 60px;
		line-height: 120%;

		text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
		background: -webkit-linear-gradient(180deg, #ac1ec3 0%, #7a17a8 100%);
		-webkit-background-clip: text;
		-webkit-text-stroke: 0.6px transparent;
	}

	&__image {
		width: 1440px;
		height: 736px;
	}

	&__image-character,
	&__image-mobile {
		display: none;
	}
}

@media (max-width: 1279px) {
	.gameplay {
		width: 375px;
		margin-bottom: 178px;

		&__title {
			width: 321px;
			margin-bottom: 70px;

			font-size: 35px;
		}

		&__image {
			display: none;
		}

		&__image-character {
			display: block;
			width: 375px;
		}

		&__image-mobile {
			display: block;
			width: 375px;
		}
	}
}
