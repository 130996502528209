.value {
	width: 370px;
	height: 186px;
	margin-bottom: 168px;

	display: flex;
	flex-direction: column;

	&__title {
		margin-bottom: 30px;

		font-weight: 900;
		font-size: 35px;
		line-height: 104%;

		text-shadow: 0px -2px 7px rgba(255, 212, 100, 0.25);
		background: linear-gradient(
			180deg,
			#ffbe46 0%,
			#ffb53d 6.25%,
			#ef7c31 52.08%,
			#ee6e2f 81.77%,
			#ed632d 100%
		);

		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	&__content {
		width: 300px;

		font-weight: 400;
		font-size: 18px;
		line-height: 167%;
		letter-spacing: -0.05px;

		opacity: 0.9;
	}
}

@media (max-width: 1279px) {
	.value {
		width: 285px;
		height: fit-content;

		margin-bottom: 60px;

		&__title {
			margin-bottom: 20px;

			font-size: 27px;
		}

		&__content {
			font-size: 15px;
		}
	}
}
