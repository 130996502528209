* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  font-family: "Nunito", sans-serif;
  color: #ffffff;
}

#root {
  width: 100%;
  height: 100%;
}