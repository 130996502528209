.pressroom {
  background: linear-gradient(180deg, #070513 0%, #1d0a24 100%);
  color: #fff;
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
}
