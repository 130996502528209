.main {
  margin: 118px 0 0;
  width: 1280px;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &__title {
    width: 910px;
    text-align: center;
    font-weight: 900;
    font-size: 80px;
    line-height: 83px;

    background: -webkit-linear-gradient(180deg, #ac1ec3 0%, #7a17a8 100%);
    -webkit-background-clip: text;
    -webkit-text-stroke: 0.6px transparent;
    text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
  }

  &__image {
    width: 1280px;
  }

  &__image-mobile {
    display: none;
  }

  &__spicy {
    margin: 0 0 128px;
    display: flex;
    width: 100%;

    &__wrapper {
      position: relative;
      min-width: 720px;
      height: 786px;

      &__image {
        position: absolute;
        height: 786px;
      }
    }

    &__image-mobile {
      display: none;
    }

    &__content {
      z-index: 1;
      display: flex;
      flex-direction: column;

      &__title {
        margin: 83px 0 50px;
        width: auto;

        text-align: left;
        font-weight: 900;
        font-size: 48px;
        line-height: 120%;

        background: -webkit-linear-gradient(180deg, #ac1ec3 0%, #7a17a8 100%);
        -webkit-background-clip: text;
        -webkit-text-stroke: 0.6px transparent;
        text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
      }

      &__label {
        width: auto;

        font-weight: 600;
        font-size: 22px;
        line-height: 155%;

        color: #f3f1f3;

        opacity: 0.95;

        &-mobile {
          display: block;
        }
      }

      &__download {
        margin-top: 21px;

        &__mob {
          display: none;
        }
        &__image {
          width: 229px;
          height: 62px;
          cursor: pointer;

          &.mr-22 {
            margin-right: 22px;
          }

          &.mb-22 {
            margin: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1279px) {
  .main {
    margin: 0;
    width: 375px;

    &__title {
      margin: 0;
      width: 270px;

      font-size: 32px;
      line-height: 105%;
    }

    &__image {
      display: none;
    }
    &__image-mobile {
      display: block;
      height: 473px;
    }

    &__spicy {
      margin: 61.6px 0 90px;

      flex-direction: column-reverse;

      &__wrapper {
        display: none;
      }

      &__image-mobile {
        display: block;
        width: 375px;
        // margin-top: 90px;
      }

      &__content {
        align-items: center;

        &__title {
          width: 285px;
          margin: 0 0 30px;

          font-size: 26px;
          line-height: 110%;
          text-align: center;
        }

        &__label {
          width: 317px;

          font-size: 15px;
          line-height: 147%;
          text-align: center;

          &-mobile {
            display: none;
          }
        }

        &__download {
          display: none;

          &__mob{
            margin-top: 41px;
            display: flex;
          }
          // flex-direction: column-reverse;

          &__image__mob {
            width: 162px;
            height: auto;

            // &.mb-22 {
            //   margin-bottom: 22px;
            // }

            &.mr-22 {
              margin-right: 9px;
            }
          }
        }
      }
    }
  }
}
