.team {
	width: 1280px;
	margin: 0 0 278px;
	// padding: 0 50px;

	display: flex;
	flex-direction: column;
	align-items: center;

	&__header {
		margin-bottom: 100px;

		display: flex;
		align-items: center;

		&__image {
			width: 60px;
			height: 60px;

			margin-right: 5px;
		}

		&__title {
			margin: 0;

			font-weight: 900;
			font-size: 60px;
			line-height: 120%;
			text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
		}
	}

	&__cards {
		width: 100%;

		display: flex;
		justify-content: center;
	}
}

@media (max-width: 1279px) {
	.team {
		width: 375px;
		margin: 0 0 70px;
		padding: 0 30px;

		&__header {
			margin-bottom: 40px;

			&__image {
				width: 35px;
				height: 35px;
			}

			&__title {
				font-size: 35px;
			}
		}

		&__cards {
			flex-direction: column;
		}
	}
}
