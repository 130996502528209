.core-values {
	width: 1280px;
	margin-bottom: 109px;

	background: radial-gradient(
		100% 84.98% at 0% 50%,
		#170a20 0%,
		rgba(23, 10, 32, 0) 92.72%
	);

	&__border {
		opacity: 0.5;

		width: 100%;
		height: 1.5px;
		background: linear-gradient(
			270deg,
			rgba(213, 84, 144, 0.61) 46.34%,
			rgba(174, 73, 120, 0) 100.77%
		);

		&.upper {
			margin-bottom: 130px;
		}

		&.bottom {
			margin-top: 68px;
		}
	}

	&__header {
		display: flex;
		align-items: center;
		margin-bottom: 100px;
		// padding: 0 50px;

		&__image {
			width: 60px;
			height: 60px;
			margin-right: 13px;
		}

		&__title {
			margin: 0;

			font-weight: 900;
			font-size: 60px;
			line-height: 120%;

			background: -webkit-linear-gradient(180deg, #ac1ec3 0%, #7a17a8 100%);
			-webkit-background-clip: text;
			-webkit-text-stroke: 0.6px transparent;
			text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
		}
	}

	&__wrapper {
		// padding: 0 100px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

@media (max-width: 1279px) {
	.core-values {
		width: 375px;
		margin-bottom: 119px;

		&__border {
			&.upper {
				margin-bottom: 57px;
			}

			&.bottom {
				margin-top: 30px;
			}
		}

		&__header {
			padding: 0 30px;
			margin-bottom: 40px;
			align-items: flex-start;

			&__image {
				width: 35px;
				height: 35px;
				margin-right: 6px;
			}

			&__title {
				width: 233px;

				font-size: 35px;
				line-height: 100%;
				text-align: left;
			}
		}

		&__wrapper {
			padding: 0 30px;
		}
	}
}
