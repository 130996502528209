.review {
	&__image {
		width: 377px;
		height: 469.3px;
	}

	&__info {
		width: 300px;
		height: 0;
		margin: 0 auto;

		position: relative;
		bottom: 30px;

		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		&__content {
			margin-bottom: 100px;
			min-height: 224px;

			display: block;

			text-align: center;
			text-transform: uppercase;

			font-weight: 600;
			font-size: 22px;
			line-height: 118%;

			opacity: 0.9;
		}

		&__name {
			display: block;

			text-align: center;
			font-weight: 900;
			font-size: 26.4px;
			line-height: 104%;

			opacity: 0.77;

			text-shadow: 0px 2.6px 1.3px rgba(38, 22, 133, 0.22),
				0px 1.04px 0px #7a17a8;
			background: -webkit-linear-gradient(180deg, #ac1ec3 0%, #7a17a8 100%);
			-webkit-background-clip: text;
			-webkit-text-stroke: 0.78px transparent;
		}
	}
}

@media (max-width: 1279px) {
	.review {
		margin-bottom: 30px;

		&__image {
			width: 312px;
			height: 390px;
		}

		&__info {
			&__content {
				margin-bottom: 38px;

				font-size: 18.26px;
				line-height: 141%;
			}

			&__name {
				font-size: 22px;
			}
		}
	}
}
