.pressroom__header {
	margin-top: 77px;
	width: 1280px;;
	display: grid;
	align-items: center;
	grid-template-columns: 7fr 1fr;

	&__image {
		width: 365px;
		height: 100px;
	}
	&__wrapper:first-child {
		margin: 0 auto;
		text-align: center;
	}
	&__icon {
		width: 60px;
		height: 60px;
	}
}

@media (max-width: 1279px) {
	.pressroom__header {
		width: auto;
		margin-top: 22px;
		display: grid;
		align-items: center;
		grid-template-columns: 5fr 1fr;

		&__image {
			width: 164px;
			height: 44px;
		}
		&__wrapper:first-child {
			margin: 0 auto;
			text-align: center;
		}
		&__icon {
			width: 30px;
			height: 30px;
		}
	}
}
