.facts-container {
	margin-bottom: 210px;
	padding: 0 100px;
	width: 1280px;

	display: flex;
	flex-direction: column;

	&__header {
		margin-bottom: 100px;

		display: flex;
		align-items: center;

		&__title {
			margin: 0;

			font-weight: 900;
			font-size: 60px;
			line-height: 120%;

			text-shadow: 0px 1.134px 0.567px rgba(38, 22, 133, 0.22),
				0px 0.4536px 0px #7a17a8;
			background: -webkit-linear-gradient(180deg, #ac1ec3 0%, #7a17a8 100%);
			-webkit-background-clip: text;
			-webkit-text-stroke: 0.6px transparent;
		}

		&__image {
			width: 60px;
			height: 60px;
		}
	}

	&__facts {
		display: flex;
		justify-content: space-between;

		&__border {
			height: 151px;
			width: 1.5px;

			background: linear-gradient(
				270deg,
				rgba(213, 84, 144, 0.61) 46.34%,
				rgba(174, 73, 120, 0) 100.77%
			);
		}
	}
}

@media (max-width: 1279px) {
	.facts-container {
		margin-bottom: 77px;
		padding: 0 57px 0 29px;
		width: 375px;

		&__header {
			margin-bottom: 40px;

			&__title {
				font-size: 35px;
			}

			&__image {
				width: 35px;
				height: 35px;
				margin-right: 5px;
			}
		}

		&__facts {
			flex-direction: column;

			&__border {
				display: none;
			}
		}
	}
}
