.pressroom-footer {
  width: 1280px;

  background: radial-gradient(
    100% 84.98% at 0% 50%,
    #170a20 0%,
    rgba(23, 10, 32, 0) 92.72%
  );

  display: flex;
  flex-direction: column;
  align-items: center;

  &__border {
    opacity: 0.5;

    width: 100%;
    height: 1.5px;
    background: linear-gradient(
      270deg,
      rgba(213, 84, 144, 0.61) 46.34%,
      rgba(174, 73, 120, 0) 100.77%
    );

    &.upper {
      margin-bottom: 129px;
    }

    &.bottom {
      margin: 85px 0 103px;
    }
  }

  &__image {
    width: 293px;
    height: 80px;
  }

  &__title {
    margin: 60px 0 41px;

    font-weight: 900;
    font-size: 90px;
    line-height: 104%;

    text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
    background: -webkit-linear-gradient(180deg, #ac1ec3 0%, #7a17a8 100%);
    -webkit-background-clip: text;
    -webkit-text-stroke: 0.6px transparent;
  }

  &__label {
    width: 669px;
    display: block;

    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 159%;
  }

  &__contact {
    cursor: pointer;

    margin: 20px 0 53px;

    font-weight: 900;
    font-size: 28px;
    line-height: 120%;

    text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
    background: -webkit-linear-gradient(180deg, #ac1ec3 0%, #7a17a8 100%);
    -webkit-background-clip: text;
    -webkit-text-stroke: 0.6px transparent;

    &-mobile {
      display: none;
    }
  }

  &__button {
    cursor: pointer;

    width: 319px;
    padding: 19px 100px;
    margin: 30px 0 138px;

    font-family: "Nunito", sans-serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 104%;
    color: #ffffff;
    text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
    background: -webkit-linear-gradient(180deg, #ac1ec3 0%, #7a17a8 100%);
    -webkit-background-clip: text;
    -webkit-text-stroke: 0.6px transparent;

    border: none;
    border-radius: 40px;
    background: linear-gradient(180deg, #df2f64 0%, #b81b6b 100%);
    box-shadow: inset 0px 2px 1px #f04294, inset 0px -3px 2px #c4267e;
  }

  &__emails {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__image {
      width: 60px;
      height: 60px;
      margin-bottom: 15px;
    }

    &__info {
      cursor: pointer;

      margin: 21px 0 0;

      font-weight: 900;
      font-size: 28px;
      line-height: 120%;
      text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
      background: -webkit-linear-gradient(180deg, #ac1ec3 0%, #7a17a8 100%);
      -webkit-background-clip: text;
      -webkit-text-stroke: 0.6px transparent;
    }

    &-mobile {
      display: none;
    }
  }

  &__info {
    width: 100%;
    padding: 0 80px;
    margin-bottom: 44px;

    display: flex;
    justify-content: space-between;

    font-weight: 600;
    font-size: 18px;
    line-height: 167%;
    opacity: 0.9;

    &__wrapper {
      width: 771px;
      display: flex;
      justify-content: space-between;

      &__rules {
        cursor: pointer;
        width: 265px;
        display: flex;
        justify-content: space-between;

        text-transform: capitalize;
      }

      &__social {
        cursor: pointer;

        text-transform: capitalize;
      }
    }
  }
}

.mr40 {
  margin-right: 40px;
}
@media (max-width: 1279px) {
  .pressroom-footer {
    width: 375px;

    &__border {
      &.upper {
        margin-bottom: 30px;
      }

      &.bottom {
        margin: 53px 0 70px;
      }
    }

    &__image {
      width: 164px;
      height: 44px;
    }

    &__title {
      margin: 20px 0 30px;

      font-size: 35px;
      line-height: 110%;
    }

    &__label {
      width: 317px;

      font-size: 15px;
      line-height: 147%;

      &.w-300 {
        width: 250px;
      }
    }

    &__contact {
      display: none;

      &-mobile {
        margin: 60px 0;

        display: flex;
        flex-direction: column;
        align-items: center;

        &__content {
          font-weight: 900;
          font-size: 20px;
          line-height: 80%;

          text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22),
            0px 0.8px 0px #7a17a8;
          background: -webkit-linear-gradient(180deg, #ac1ec3 0%, #7a17a8 100%);
          -webkit-background-clip: text;
          -webkit-text-stroke: 0.6px transparent;
        }
      }
    }

    &__button {
      padding: 17px 100px;
      margin: 30px 40px 77px;

      font-size: 22px;
    }

    &__emails {
      display: none;

      &-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__info {
          margin-bottom: 40px;
          display: block;

          text-align: center;
          font-weight: 900;
          font-size: 20px;
          line-height: 130%;

          text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22),
            0px 0.8px 0px #7a17a8;
          background: -webkit-linear-gradient(180deg, #ac1ec3 0%, #7a17a8 100%);
          -webkit-background-clip: text;
          -webkit-text-stroke: 0.6px transparent;
        }
      }
    }

    &__info {
      padding: 0 30px;
      margin-bottom: 30px;

      flex-direction: column-reverse;

      font-size: 16px;
      line-height: 187%;

      &__wrapper {
        width: 100%;
        margin-bottom: 79px;

        &__rules {
          flex-direction: column;
          margin-bottom: 8px;
        }

        &__social {
          display: flex;
          flex-direction: column;
          margin-bottom: 8px;
        }
      }
    }
  }
  .mr40 {
    margin-right: 0;
  }
}
