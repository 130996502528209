.reviews-container {
  width: 1280px;
  // padding: 0 100px;
  margin-bottom: 235px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    margin-bottom: 100px;

    display: flex;
    align-items: center;

    &__image {
      width: 80px;
      height: 95px;
      margin-right: 5px;
      padding-bottom: 15px;
    }

    &__title {
      margin: 0;

      font-weight: 900;
      font-size: 60px;
      line-height: 120%;

      text-shadow: 0px 2px 1px rgba(38, 22, 133, 0.22), 0px 0.8px 0px #7a17a8;
      background: -webkit-linear-gradient(180deg, #ac1ec3 0%, #7a17a8 100%);
      -webkit-background-clip: text;
      -webkit-text-stroke: 0.6px transparent;
    }
  }

  &__wrapper {
    width: 100%;

    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 1279px) {
  .reviews-container {
    width: 375px;
    padding: 0 30px;
    margin-bottom: 145px;

    &__header {
      margin-bottom: 40px;

      &__image {
        width: 45px;
        height: 60px;
        margin-right: 3px;
        padding-bottom: 12px;
      }

      &__title {
        font-size: 35px;
      }
    }

    &__wrapper {
      flex-direction: column;
    }
  }
}
